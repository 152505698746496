<template>
  <section v-if="tagDict && images"> 
    <b-card 
      :id="tagDict.id"
      border-variant="secondary"
      header-border-variant="secondary"
      header-tag="header" 
      footer-tag="footer"
    >
      <template #header>
        {{ tagDict.label.children[0].value }}
      </template>
      <br>
      <b-card 
        align="center"
      >
        <graphic-tag 
          :tag="tagDict.graphic" 
          :images="images"
        />
      </b-card>
      <template #footer>
        <span v-if="selected === 'figures'">
          <b-button
            variant="flat-primary"
            @click.stop="showCaption = !showCaption"
          >
            <span v-if="showCaption">Hide Caption</span>
            <span v-else>Show Caption</span>
          </b-button>
          <b-button
            variant="flat-primary"
            @click.stop="scrollToRef(tag.attrs.id)"
          >
            View in Article
          </b-button>
          <span v-if="showCaption" > <br><br></span>
        </span>
        <span v-if="selected == 'article' || selected == null || showCaption">
          <span v-for="(child, index) in tagDict.caption.children"  :key="index">
            <p-tag 
              v-if="child.tagName === 'p'"
              :tag="child" 
              :images="images"
            />
            <span v-else>
              <b> TODO UNSUPPORTED in FigTag:</b> {{ child.tagName }} 
            </span>
          </span> 
        </span>
      </template>
    </b-card>
  </section>
</template>
 
<script> 

import {
  BCard, BButton,
} from 'bootstrap-vue'

import GraphicTag from './GraphicTag.vue'
//import PTag from './PTag.vue'

export default {

  components: {
    BCard,
    GraphicTag,
    BButton,
    //components that may be recursive can be done this way
    //https://stackoverflow.com/questions/49154490/did-you-register-the-component-correctly-for-recursive-components-make-sure-to
    PTag: () => import('./PTag.vue'),
  }, 
  props: {
    tag: {
      type: Object,
      default: null
    },
    images: {
      type: Object,
      default: null
    },
    selected: {
      type: String,
      default: null
    },
  },
  data () {
    return {
      tagDict: null,
      showCaption: false,
    }
  },
  mounted () { 
    //console.log('FigTag mounted', this.tag)
    const temp = { id: this.tag.attrs.id }
    for (let i = 0; i < this.tag.children.length; i++) {
      const child = this.tag.children[i]
      temp[child.tagName] = child
    } 
    this.tagDict = temp
    //console.log('xxxx', this.tagDict)
  },
  methods: { 
    //this one called from sidebar
    // also see scroll to ref in XrefTag.vue which is called when viewing article web page.
    scrollToRef (refName) {
      
      // xml markup has id attribute and  not have ref attribute
      //const element = this.$refs[refName]
      const element = document.getElementById(refName)
      console.log('scrolling to elel', refName, element)
      //element.scrollIntoView({ behavior: 'smooth' })
      const yOffset = -100
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })

      this.$emit('CloseSidebarRequested') 

    },
  },
}
</script>
