<template>
  <span v-if="tag" :id="'body-'.concat(tag.attrs.rid)">

    <a v-if="tag.attrs['ref-type']" href="#" @click.prevent="scrollToRefAsync(tag.attrs)"> 
      <span v-for="(child, index) in tag.children" :key="index">
        <span v-if="child.type ==='text'">
          <span v-if="tag.attrs['ref-type']">
            <b>{{ child.value }}</b>
          </span>
          <span v-else>
            {{ child.value }} 
          </span>
        </span>
        <tag-dispatcher
          v-else
          :tag="child"
          :parent="tag.tagName"
        />
      </span> 
    </a>
    <span v-else-if="tag.attrs['xlink:href']">
      <a :href="tag.attrs['xlink:href']" target="_blank"> 
        <span v-for="(child, index) in tag.children" :key="index">
          <span v-if="child.type ==='text'">
            <span v-if="tag.attrs['ref-type']">
              <b>{{ child.value }}</b>
            </span>
            <span v-else>
              {{ child.value }} 
            </span> 
          </span>
          <tag-dispatcher
            v-else
            :tag="child"
            :parent="tag.tagName"
          />>
        </span> 
      </a>
    </span>
    <span v-else> 
      <b> TODO UNSUPPORTED XrefTag: {{ child }} </b>
    </span>

  </span>
</template>
 
<script>

import {
} from 'bootstrap-vue'

export default {

  components: {
    //components that may be recursive can be done this way
    //https://stackoverflow.com/questions/49154490/did-you-register-the-component-correctly-for-recursive-components-make-sure-to
    TagDispatcher: () => import('./TagDispatcher.vue'),
  }, 
  props: {
    tag: {
      type: Object,
      default: null
    },
  },
  data () {
    return {
    }
  },
  mounted () { 
    /*
    if (this.tag.attrs.rid === 'r12') {
      console.log('XrefTag mounted', this.tag)
    }
    if (this.tag.attrs['ref-type'] !== 'bibr') {
      console.log('XrefTag mounted', this.tag)
    }
    */
    if (!this.tag.attrs['ref-type'] && this.tag.tagName !== 'ext-link') {
      console.log('XrefTag mounted', this.tag)
    } 

  },
  methods: { 
    scrollToRefAsync(attrs) {
      //console.log('9999')
      setTimeout(() => this.scrollReady(attrs), 500) 
    },
    scrollReady (attrs) {
      //console.log('tttttt ref ready', id, this.$refs[id])
      this.scrollToRef(attrs)
    },
    scrollToRef (attrs) {
      let elementName = attrs.rid
      if (attrs['ref-type'] && attrs['ref-type'] === 'bibr') {
        elementName = attrs['ref-type'].concat('-', attrs.rid)
      }
      // xml markup has id attribute and  not have ref attribute
      //const element = this.$refs[elementName]
      const element = document.getElementById(elementName)
      console.log('XRef scrolling to elel', elementName, element)
      //element.scrollIntoView({ behavior: 'smooth' })
      const yOffset = -100
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    },
  },
}
</script>
