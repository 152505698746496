<template>
  <span> 
    <span v-if="tag.tagName === 'disp-formula'" :id="tag.attrs.id">
      <br> <br>
      <span v-for="(child, index) in tag.children"  :key="index">
        <b-row v-if="child.tagName ==='label'">
          <b-col cols="11"></b-col>
          <b-col cols="1"><b>{{ child.children[0].value }}</b></b-col>
        </b-row>
        <b-row v-else-if="child.tagName ==='math'">
          <b-col cols="2"></b-col>
          <b-col cols="8">
            <math-tag 
              :tag="child" 
            />
          </b-col>
          <b-col cols="2"></b-col>
        </b-row>
        <tag-dispatcher
          v-else
          :tag="child"
          :parent="tag.tagName"
          :images="images"
        />
      </span>
      <br> <br>
    </span>
    <span v-else>
      <span v-for="(child, index) in tag.children"  :key="index">
        <tag-dispatcher
          :tag="child"
          :parent="tag.tagName"
          :images="images"
        />
      </span>
    </span>
  </span>
</template>
 
<script>
 
import {
  BRow, BCol
} from 'bootstrap-vue'

import MathTag from './MathTag.vue'

export default {

  components: { 
    BRow,
    BCol,
    MathTag,
    //components that may be recursive can be done this way
    //https://stackoverflow.com/questions/49154490/did-you-register-the-component-correctly-for-recursive-components-make-sure-to
    TagDispatcher: () => import('../TagDispatcher.vue'),
  }, 
  props: {
    tag: {
      type: Object,
      default: null
    },
    images: {
      type: Object,
      default: null
    },
  },
  data () {
    return {
    }
  },
  mounted () { 
    if (this.tag.tagName === 'disp-formula') {
      //console.log('FormulaTag mounted', this.tag)
    }
  },
  updated () { 
    //Don't do this here as page will timeout when doiing this for each formula.
    // instead do it at the page level one time see ArticleDetailWeb
    //window.MathJax.typeset()
  },
  methods: {  
  },
}
</script>
