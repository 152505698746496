<template>
  <span v-if="tag && images"> 
    <b-img :src="imageLinkOn(tag.attrs['xlink:href'])" fluid alt="Responsive image" />
  </span>
</template>
 
<script>
 
import {
  BImg,
} from 'bootstrap-vue'

export default {

  components: {
    BImg,
  }, 
  props: {
    tag: {
      type: Object,
      default: null
    },
    images: {
      type: Object,
      default: null
    },
  },
  data () {
    return {
    }
  },
  computed: {
  },
  watch: {
  }, 
  mounted () { 
    if (this.tag.tagName === 'inline-graphic') {
      //console.log('GraphicTag mounted', this.tag)
    }
  },
  methods: { 
    imageLinkOn (imageFile) {
      //console.log('In GraphicTag imageLinkOn A:  ', imageFile)
      let delim = '.tif'
      if (!imageFile.endsWith(delim)) {
        delim = '.jpeg'
        if (!imageFile.endsWith(delim)) {
          delim = '.gif'
          if (!imageFile.endsWith(delim)) {
            delim = null
          }
        }
      }
      let imageName = imageFile
      if (delim != null) {
        imageName = imageName.split(delim)[0]
      }
      //console.log('In GraphicTag imageLinkOn :B', imageName, this.images)
      const rec = this.images[imageName]
      if (rec) {
        //console.log('In GraphicTag imageLinkOn C:  ', rec)
        return rec.url
      } 
      return ''
    },
  },
}
</script>
