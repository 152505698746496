<template>
  <span> 
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span v-html="tag.html"></span>
  </span>
</template>
 
<script>

import {
} from 'bootstrap-vue'

export default {

  components: {  
  }, 
  props: {
    tag: {
      type: Object,
      default: null
    },
  },
  data () {
    return {
    }
  },
  mounted () { 
    //console.log('MathTag mounted', this.tag)
  },
  updated () { 
    //Don't do this here as page will timeout when doiing this for each formula.
    // instead do it at the page level one time see ArticleDetailWeb
    //window.MathJax.typeset()
  },
  methods: {  
  },
}
</script>
