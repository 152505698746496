<template>
  <section v-if="tagDict && images"> 
    <b-card 
      :id="tagDict.id"
      header-tag="header" 
      footer-tag="footer"
    >
      <template #header>
        <b-row>
          <b-col cols="12">
            <b>{{ tagDict.label.children[0].value }}</b>
            <span v-if="selected === 'tables'">
              <b-button
                variant="flat-primary"
                @click.stop="scrollToRef(tagDict.id)"
              >
                View in Article
              </b-button> 
            </span>
          </b-col>
        </b-row>
        <br>
        <span>
          <span v-for="(child, index) in tagDict.caption.children"  :key="index">
            <p-tag 
              v-if="child.tagName === 'p'"
              :tag="child" 
              :images="images"
            />
            <span v-else>
              <b> TODO UNSUPPORTED in TableWrapTag1:</b> {{ child.tagName }} 
            </span>
          </span> 
        </span>
      </template>

      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="tagDict.table.html"></span>
      <template v-if="tagDict['table-wrap-foot']" #footer>
        <span v-for="(child, index) in tagDict['table-wrap-foot'].children"  :key="index">
          <p-tag 
            v-if="child.tagName === 'p'"
            :tag="child" 
            :images="images"
          />
          <fn-tag 
            v-else-if="child.tagName === 'fn'"
            :tag="child" 
            :images="images"
          />
          <span v-else>
            <b> TODO UNSUPPORTED in TableWrapTag2:</b> {{ child.tagName }} 
          </span>
        </span> 

      </template>
    </b-card>
  </section>
</template>
 
<script> 

import {
  BCard, BButton, BRow, BCol,
} from 'bootstrap-vue'

//import PTag from './PTag.vue'
import FnTag from './FnTag.vue'

export default {

  components: {
    BCard,
    BButton,
    //components that may be recursive can be done this way
    //https://stackoverflow.com/questions/49154490/did-you-register-the-component-correctly-for-recursive-components-make-sure-to
    PTag: () => import('./PTag.vue'),
    FnTag,
    BRow, 
    BCol,
  }, 
  props: {
    tag: {
      type: Object,
      default: null
    },
    images: {
      type: Object,
      default: null
    },
    selected: {
      type: String,
      default: null
    },
  },
  data () {
    return {
      tagDict: null,
    }
  },
  mounted () { 
    //onsole.log('TableWrapTag mounted', this.tag)
    const temp = { id: this.tag.attrs.id }
    for (let i = 0; i < this.tag.children.length; i++) {
      const child = this.tag.children[i]
      temp[child.tagName] = child
    } 
    this.tagDict = temp
    //console.log('xxxx', this.tagDict)
  },
  methods: { 
    //this one called from sidebar
    // also see scroll to ref in XrefTag.vue which is called when viewing article web page.
    scrollToRef (refName) {
      
      // xml markup has id attribute and  not have ref attribute
      //const element = this.$refs[refName]
      const element = document.getElementById(refName)
      console.log('scrolling to elel', element)
      //element.scrollIntoView({ behavior: 'smooth' })
      const yOffset = -100
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })

      this.$emit('CloseSidebarRequested')

    },
  },
}
</script>
<style>
  table,
  th,
  td {
    padding: 10px;
    border: 1px solid black;
    border-collapse: collapse;
  }
</style>
