<template>
  <span v-if="tag"> 
    <span v-if="selected === 'article' || selected == null">  
      <sec-tag
        v-if="tag.tagName === supportedTags.sec"
        :id="tag.attrs.id"
        :tag="tag" 
        :images="images"
        :selected="selected"
      />       
      <p-tag 
        v-else-if="tag.tagName === supportedTags.p"
        :tag="tag" 
        :images="images"
        :selected="selected"
      />
      <disp-quote-tag
        v-else-if="tag.tagName ===  supportedTags['disp-quote']"
        :tag="tag" 
        :selected="selected"
        :images="images"
      />
      <table-wrap-tag 
        v-else-if="tag.tagName ===  supportedTags['table-wrap']"
        :tag="tag" 
        :images="images"
        :selected="selected"
      />
      <x-ref-tag 
        v-else-if="tag.tagName ===  supportedTags.xref || tag.tagName ===  supportedTags['ext-link']"
        :tag="tag" 
      />
      <formula-tag 
        v-else-if="tag.tagName ===  supportedTags['inline-formula'] || tag.tagName ===  supportedTags['disp-formula']"
        :tag="tag" 
        :images="images"
      />
      <math-tag 
        v-else-if="tag.tagName ===  supportedTags.math"
        :tag="tag"
        :images="images"
      />
      <graphic-tag 
        v-else-if="tag.tagName ===  supportedTags['inline-graphic']"
        :tag="tag"
        :images="images"
      />
      <fig-tag 
        v-else-if="tag.tagName ===  supportedTags.fig"
        :tag="tag" 
        :images="images"
      />
      <fn-tag 
        v-else-if="tag.tagName ===  supportedTags.fn"
        :tag="tag" 
        :images="images"
      />
      <em v-else-if="tag.tagName ===  supportedTags.italic">
        {{ tag.children[0].value }}
      </em>
      <sub v-else-if="tag.tagName ===  supportedTags.sub">
        {{ tag.children[0].value }}
      </sub>
      <sup v-else-if="tag.tagName ===  supportedTags.sup">
        {{ tag.children[0].value }}
      </sup>
      <strong v-else-if="tag.tagName ===  supportedTags.bold">
        {{ tag.children[0].value }}
      </strong>
      <font
        v-else-if="tag.tagName ===  supportedTags.sc"
        style="font-variant: small-caps"
      >
        {{ tag.children[0].value }}
      </font>
      <span v-else-if="tag.type === 'tag'"> 
        <b> TODO UNSUPPORTED In {{ parent }}: {{ tag.tagName }} </b>
      </span>
    </span>
  </span>
</template>
 
<script>

import {
} from 'bootstrap-vue'

import XRefTag from './XRefTag.vue'
import FormulaTag from './math/FormulaTag.vue'
import MathTag from './math/MathTag.vue'
import GraphicTag from './GraphicTag.vue'
import FigTag from './FigTag.vue'
import TableWrapTag from './TableWrapTag.vue'
import FnTag from './FnTag.vue'

export default {

  components: { 
    XRefTag,
    FormulaTag,
    MathTag,
    GraphicTag,
    FigTag,
    TableWrapTag,
    FnTag,
    //components that may be recursive can be done this way
    //https://stackoverflow.com/questions/49154490/did-you-register-the-component-correctly-for-recursive-components-make-sure-to
    SecTag: () => import('./SecTag.vue'),
    PTag: () => import('./PTag.vue'),

  }, 
  props: {
    tag: {
      type: Object,
      default: null
    },
    parent: {
      type: String,
      default: null
    },
    images: {
      type: Object,
      default: null
    },
    selected: {
      type: String,
      default: null
    },
  },
  data () {
    return {
    }
  },
  computed: {
    //note . keep this in synch with the server side definition in TagDispatcher
    supportedTags() {
      return {
        bold: 'bold',
        'disp-quote': 'disp-quote',
        'disp-formula': 'disp-formula',
        'ext-link': 'ext-link',
        fig: 'fig',
        fn: 'fn',
        'inline-formula': 'inline-formula',
        'inline-graphic': 'inline-graphic',
        italic: 'italic',
        math: 'math',
        p: 'p',
        sc: 'sc',
        sec: 'sec',
        sub: 'sub',
        sup: 'sup',
        'table-wrap': 'table-wrap',
        xref: 'xref',
      }
    },

  },
  mounted () { 
    //console.log('PTag mounted', this.tag)
  },
  methods: { 
  },
}
</script>
